import { FunctionComponent, SVGProps } from 'react';

export const HeartIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="caret-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            {...props}
        >
            <path d="M16,5.095c0-2.2551-1.8804-4.0833-4.2-4.0833c-1.6824,0-3.1295,0.964-3.8,2.3527 C7.3295,1.9756,5.8824,1.0117,4.2,1.0117C1.8804,1.0117,0,2.8398,0,5.095c0,0.0657,0.007,0.1296,0.0101,0.1945H0.0036 C0.0051,5.3358,0.0147,5.385,0.0175,5.4318c0.004,0.0477,0.0082,0.0952,0.0139,0.1425c0.0696,0.7995,0.321,1.6629,0.8238,2.5722 C2.0732,10.3546,4.2325,12.018,7.9998,15c3.7672-2.982,5.9265-4.6466,7.1445-6.8535c0.5009-0.9058,0.752-1.7661,0.8229-2.5629 c0.0067-0.0543,0.0115-0.1089,0.016-0.1638c0.0024-0.0428,0.0114-0.088,0.0127-0.1304h-0.0059C15.993,5.2245,16,5.1606,16,5.095z" />
        </svg>
    );
};

export default HeartIcon;
