import { FunctionComponent, SVGProps } from 'react';

export const LaptopHouseLightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 640 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M624,416H592V288c0-17.68-12.9-32-28.81-32H332.73c-15.91,0-28.8,14.32-28.8,32V416h-32a16,16,0,0,0-16,16v37.34A42.82,42.82,0,0,0,298.53,512H597.39A42.83,42.83,0,0,0,640,469.33V432A16,16,0,0,0,624,416ZM335.93,288H560V416h-224ZM608,469.26A10.76,10.76,0,0,1,597.39,480H298.64a10.78,10.78,0,0,1-10.72-10.67V448H608ZM197.28,160A21.41,21.41,0,0,0,175.9,181.3v85.35A21.42,21.42,0,0,0,197.28,288h74.64a64.32,64.32,0,0,1,32-56V181.3A21.44,21.44,0,0,0,282.59,160Zm74.64,96h-64V192h64Zm-176,112V168a18.8,18.8,0,0,0-.81-4L239.91,37.22l144,126V224h32V191.25L453.34,224h20a15.71,15.71,0,0,0,6.2-12.33A18.31,18.31,0,0,0,474.49,200L416,148.72V48a16,16,0,0,0-32,0v72.75L250.45,4a16,16,0,0,0-21.07,0L5.33,200A18.24,18.24,0,0,0,0,211.89a16,16,0,0,0,16,16,18.62,18.62,0,0,0,10.4-3.83l37.47-32.82V368a48.1,48.1,0,0,0,48,48h112V384h-112A16,16,0,0,1,95.88,368Z" />
        </svg>
    );
};

export default LaptopHouseLightIcon;
