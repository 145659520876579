import { FunctionComponent, SVGProps } from 'react';

export const CameraHomeLightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="camera-home"
            role="img"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            {...props}
        >
            <path d="M208,128a64.06,64.06,0,0,0-64,64,16,16,0,0,0,32,0,32,32,0,0,1,32-32,16,16,0,0,0,0-32Zm15.94-64a144,144,0,0,0,0,288H224a144,144,0,0,0-.05-288Zm0,256a112,112,0,0,1,0-224H224a112,112,0,0,1-.05,224ZM384,0H64A64,64,0,0,0,0,64V352a64,64,0,0,0,64,64h72.71L76.53,450.83c-6.9,4.33-12.5,14.45-12.5,22.6v11.9A26.68,26.68,0,0,0,90.7,512H357.37A26.68,26.68,0,0,0,384,485.33V473.45c0-8.17-5.65-18.3-12.58-22.62L311.28,416H384a64,64,0,0,0,64-64V64A64,64,0,0,0,384,0ZM352,476.54V480H96v-3.48l56.71-32.82L200.59,416H247.4l47.85,27.7ZM416,352a32,32,0,0,1-32,32H64a32,32,0,0,1-32-32V64A32,32,0,0,1,64,32H384a32,32,0,0,1,32,32Z" />
        </svg>
    );
};

export default CameraHomeLightIcon;
