import {
    ElementType,
    forwardRef,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from '../../types/PolymorphicProps';

const TYPE_CLASSES = {
    primary: styles.primary,
    danger: styles.danger,
    warning: styles.warning,
    disabled: styles.disabled,
    default: styles.default,
    textButton: styles.textButton,
    success: styles.success,
    textDangerButton: styles.textDangerButton,
} as const;

export type ButtonProps<TElement extends ElementType> = PolymorphicComponentPropsWithRef<
    TElement,
    {
        preset?: keyof typeof TYPE_CLASSES;
        disabled?: boolean;
        icon?: ReactNode;
        /** Only show the icon with no text on small screens */
        iconOnlyMobile?: boolean;
    }
>;

type ButtonComponent = (<TElement extends ElementType = 'button'>(
    props: ButtonProps<TElement>
) => ReactNode | null) & { displayName?: string | undefined };

export const Button: ButtonComponent = forwardRef(
    <TElement extends ElementType = 'button'>(
        {
            as,
            preset,
            iconOnlyMobile,
            onClick,
            icon = null,
            disabled = false,
            children,
            ...props
        }: ButtonProps<TElement>,
        ref?: PolymorphicRef<TElement>
    ) => {
        const Component = as || 'button';
        const [asyncDisabled, setAsyncDisabled] = useState<boolean>(false);

        const onClickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
            (event) => {
                if (!onClick) {
                    return;
                }

                const handler = onClick(event);

                if (handler && handler instanceof Promise) {
                    setAsyncDisabled(true);

                    return handler.finally(() => {
                        setAsyncDisabled(false);
                    });
                }
            },
            [onClick]
        );

        const isDisabled = useMemo(() => {
            if (asyncDisabled) {
                return true;
            }

            return disabled;
        }, [asyncDisabled, disabled]);

        const typeClass = TYPE_CLASSES[preset || 'default'];
        return (
            <Component
                ref={ref}
                disabled={isDisabled}
                {...props}
                onClick={onClickHandler}
                className={classnames(props.className, styles.button, typeClass, {
                    [styles.iconOnlyMobile]: iconOnlyMobile,
                })}
            >
                {icon ? <div className={styles.icon}>{icon}</div> : null}
                {children}
            </Component>
        );
    }
);
Button.displayName = 'Button';

export default Button;
