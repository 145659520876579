import { FunctionComponent, SVGProps } from 'react';

export const CameraHomeSolidIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M0 64C0 28.7 28.7 0 64 0H384c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H310l59 36.9c12.1 7.6 17.7 22.2 13.8 36S366.3 512 352 512H96c-14.3 0-26.8-9.5-30.8-23.2s1.7-28.4 13.8-36L138 416H64c-35.3 0-64-28.7-64-64V64zM128 208a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0A128 128 0 1 0 96 208a128 128 0 1 0 256 0z" />
        </svg>
    );
};

export default CameraHomeSolidIcon;
