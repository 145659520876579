import { FunctionComponent, SVGProps } from 'react';

export const UserGroupSimpleSolidIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="640"
            height="512"
            viewBox="0 0 640 512"
            fill="#3D56E0"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M160 32a104 104 0 1 1 0 208 104 104 0 1 1 0-208zm320 0a104 104 0 1 1 0 208 104 104 0 1 1 0-208zM0 416c0-70.7 57.3-128 128-128h64c70.7 0 128 57.3 128 128v16c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V416zm448 64c-38.3 0-72.7-16.8-96.1-43.5c.1-1.5 .1-3 .1-4.5V416c0-34.9-11.2-67.1-30.1-93.4c5.8-20 24.2-34.6 46.1-34.6H592c26.5 0 48 21.5 48 48v16c0 70.7-57.3 128-128 128H448z" />
        </svg>
    );
};

export default UserGroupSimpleSolidIcon;
