import { FunctionComponent, SVGProps } from 'react';

export const GiftCardLightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 576 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M528 128h-71.3c9.5-13.4 15.3-29.9 15.3-48 0-44.1-33.4-80-74.5-80-42.3 0-66.8 25.4-109.5 95.8C245.3 25.4 220.8 0 178.5 0 137.4 0 104 35.9 104 80c0 18.1 5.8 34.6 15.3 48H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zm-219.8-3.6c49.5-83.3 66-92.4 89.3-92.4 23.4 0 42.5 21.5 42.5 48s-19.1 48-42.5 48H306l2.2-3.6zM178.5 32c23.4 0 39.8 9.1 89.3 92.4l2.1 3.6h-91.5c-23.4 0-42.5-21.5-42.5-48 .1-26.5 19.2-48 42.6-48zM544 464c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16v-32h512v32zm0-64H32v-64h512v64zm0-96H32V176c0-8.84 7.16-16 16-16h201.38l-79.03 79.03c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L288 166.62l95.03 95.03c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L326.62 160H528c8.84 0 16 7.16 16 16v128z" />
        </svg>
    );
};

export default GiftCardLightIcon;
