import { FunctionComponent, SVGProps } from 'react';

export const UsersClassLightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 640 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M544 224c-44.2 0-80 35.8-80 80s35.8 80 80 80 80-35.8 80-80-35.8-80-80-80zm0 128c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-304-48c0 44.2 35.8 80 80 80s80-35.8 80-80-35.8-80-80-80-80 35.8-80 80zm128 0c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zM96 384c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm468 160h-40c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zm-448 0H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zm224 0h-40c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM64 48c0-8.83 7.19-16 16-16h480c8.81 0 16 7.17 16 16v149.22c11.51 3.46 22.37 8.36 32 15.11V48c0-26.47-21.53-48-48-48H80C53.53 0 32 21.53 32 48v164.33c9.63-6.75 20.49-11.64 32-15.11V48z" />
        </svg>
    );
};

export default UsersClassLightIcon;
